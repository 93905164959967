<template>
  <v-container class="courseupcominginner container-custom">
    <div v-if="allTraining.results">
      <div class="formjoin absModal">
        <div class="insideBox">
          <div
            style="
              display: flex;
              justify-content: space-between;
              align-items: flex-end;
              background-color: #1976d2;
              padding: 10px;
              border-radius: 10px 10px 0 0;
            "
          >
            <h6 style="font-size: 20px; color: #fff">Request For Callback</h6>

            <v-icon
              @click="closePopup"
              style="color: #fff; position: relative; top: -2px"
              >mdi-close</v-icon
            >
          </div>

          <v-form
            ref="formUpcoming"
            v-model="valid"
            lazy-validation
            class=""
            style="padding: 20px 20px 20px 20px"
          >
            <v-text-field
              v-model="fullNameUp"
              outlined
              label="Full Name"
              :rules="[(v) => !!v || 'Name is required']"
            ></v-text-field>
            <v-text-field
              v-model="phoneUp"
              outlined
              label="Phone"
              :rules="[
                (v) => !!v || 'Phone is required',
                (v) =>
                  (v && v.length <= 10) ||
                  'Phone must be less than 10 characters',
              ]"
            ></v-text-field>
            <v-text-field
              v-model="emailUp"
              outlined
              label="Email"
              :rules="[
                ((v) => !!v || 'E-mail is required',
                (v) => /.+@.+\..+/.test(v) || 'E-mail must be valid'),
              ]"
            ></v-text-field>

            <v-btn
              type="button"
              :loading="submitLoading"
              class="primary"
              @click="submitFormUpcoming"
              style=""
              >Book Now</v-btn
            >
          </v-form>
        </div>
      </div>
    </div>
  </v-container>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "ContactForm",
  props: ["percentage"],
  data: () => ({
    bannerUrl: require("@/assets/placeholder.jpg"),
    dialog: false,
    valid: true,
    fullNameUp: "",
    emailUp: "",
    phoneUp: "",
    submitLoading: false,
  }),
  methods: {
    closePopup() {
      this.$emit("closepopup", false);
    },
    async submitFormUpcoming() {
      if (this.$refs.form.validate()) {
        this.submitLoading = true;
        const data = {
          fullName: this.fullName,
          email: this.email,
          phone: this.phone,
          subject: this.$route.params.slug,
          trainingId: this.allTraining.results._id,
        };
        await this.sendEnquiry(data);
        this.$refs.form.reset();

        this.submitLoading = false;
        this.snackbar = true;
      }
    },
  },
  computed: {
    ...mapGetters(["allTraining"]),
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/variables.scss";

.courseupcoming {
  padding: 40px 0;
  .courseupcominginner {
    .btncta {
      background-color: #3773ae;
      display: block;
      text-align: center;
      padding: 15px;
      border-radius: 10px;
      color: #fff;
      text-transform: uppercase;
      font-size: 20px;
      font-weight: 600;
      letter-spacing: 0.6px;
    }
    .btncta:hover {
      background-color: #fff;
      color: #3773ae;
      border: 2px solid #3773ae;
    }
    .btncta1 {
      background-color: #fff;
      display: block;
      text-align: center;
      padding: 15px;
      border-radius: 10px;
      border: 2px solid #3773ae;
      color: #3773ae;
      text-transform: uppercase;
      font-size: 20px;
      font-weight: 600;
      letter-spacing: 0.6px;
    }
    .btncta1:hover {
      background-color: #3773ae;
      color: #fff;
    }

    .subtitle {
      padding-top: 20px;

      h4 {
        font-size: 30px;
        padding: 0;
      }

      p {
        text-align: center;
        width: 60%;
        margin: 0 auto;
        padding: 15px 0;
      }

      .btncta2 {
        margin: 0 auto;
        background-color: transparent;
        display: block;
        width: 30%;
        text-align: center;
        padding: 15px 0;
        border-radius: 10px;
        border: 1px solid #3773ae;
        color: #3773ae;
        text-transform: uppercase;
        font-size: 20px;
        font-weight: 600;
        letter-spacing: 0.6px;
      }

      .btncta2:hover {
        background-color: #1876d2;
        color: #fff;
      }
    }

    .coursecard {
      border: 1px solid #ff9f00;
      border-radius: 10px;
      width: 600px;
      margin: 0 auto;
      padding: 20px;
      display: flex;
      gap: 30px;
      align-items: start;

      .datee {
        h6 {
          line-height: 22px;
          font-size: 32px;
          text-align: center;
          color: #ff9f00;
        }

        span {
          &:nth-child(2) {
            font-size: 18px;
            font-weight: normal;
          }
        }
      }

      .coursedetails {
        h5 {
          color: #363636;
          font-size: 20px;
          font-weight: bold;
          text-align: left;
          text-transform: uppercase;
          margin: 0 auto;
        }

        p {
          line-height: 26px;
        }

        ul {
          line-height: 26px;

          li {
            padding: 0px;
            display: block;
            margin: 0;
          }
        }
      }
    }
  }
}
.courseupcoming {
  padding: 80px 0;

  .courseupcominginner {
    width: 100%;

    h4 {
      color: #363636;
      font-size: 36px;
      font-weight: bold;
      padding-bottom: 15px;
      display: flex;
      flex-direction: column;
      text-align: center;
      text-transform: uppercase;
      margin: 0 auto;
      line-height: 44px;
    }
  }
}

.absModal {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: rgba(0, 0, 0, 0.5);
  z-index: 99;
}
.insideBox {
  background: #fff;
  border-radius: 10px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 500px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}
.paymentList {
  display: flex;
  list-style: none;
  margin: 0;
  padding: 0 20px;
  margin-bottom: 10px;
  margin-top: 10px;
  li {
    padding: 5px 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
    margin-right: 10px;
    cursor: pointer;
  }
  .active {
    border: 1px solid #1876d2;
  }
}
@media screen and (max-width: 600px) {
  .insideBox {
    width: 95%;
  }
  .coursecard {
    width: 100% !important;
    display: flex;
    flex-direction: column;
  }
  .btncta,
  .btncta1 {
    font-size: 12px !important;
  }
}
</style>
