var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-container',{staticClass:"courseupcominginner container-custom"},[(_vm.allTraining.results)?_c('div',[_c('div',{staticClass:"formjoin absModal"},[_c('div',{staticClass:"insideBox"},[_c('div',{staticStyle:{"display":"flex","justify-content":"space-between","align-items":"flex-end","background-color":"#1976d2","padding":"10px","border-radius":"10px 10px 0 0"}},[_c('h6',{staticStyle:{"font-size":"20px","color":"#fff"}},[_vm._v("Request For Callback")]),_c('v-icon',{staticStyle:{"color":"#fff","position":"relative","top":"-2px"},on:{"click":_vm.closePopup}},[_vm._v("mdi-close")])],1),_c('v-form',{ref:"formUpcoming",staticStyle:{"padding":"20px 20px 20px 20px"},attrs:{"lazy-validation":""},model:{value:(_vm.valid),callback:function ($$v) {_vm.valid=$$v},expression:"valid"}},[_c('v-text-field',{attrs:{"outlined":"","label":"Full Name","rules":[(v) => !!v || 'Name is required']},model:{value:(_vm.fullNameUp),callback:function ($$v) {_vm.fullNameUp=$$v},expression:"fullNameUp"}}),_c('v-text-field',{attrs:{"outlined":"","label":"Phone","rules":[
              (v) => !!v || 'Phone is required',
              (v) =>
                (v && v.length <= 10) ||
                'Phone must be less than 10 characters',
            ]},model:{value:(_vm.phoneUp),callback:function ($$v) {_vm.phoneUp=$$v},expression:"phoneUp"}}),_c('v-text-field',{attrs:{"outlined":"","label":"Email","rules":[
              ((v) => !!v || 'E-mail is required',
              (v) => /.+@.+\..+/.test(v) || 'E-mail must be valid'),
            ]},model:{value:(_vm.emailUp),callback:function ($$v) {_vm.emailUp=$$v},expression:"emailUp"}}),_c('v-btn',{staticClass:"primary",attrs:{"type":"button","loading":_vm.submitLoading},on:{"click":_vm.submitFormUpcoming}},[_vm._v("Book Now")])],1)],1)])]):_vm._e()])
}
var staticRenderFns = []

export { render, staticRenderFns }